.footer-bg {
  background: hsla(0, 0%, 100%, 0.7) !important;
  box-shadow: 0 10px 20px rgb(0 0 0 / 30%), 0 6px 6px rgb(0 0 0 / 23%);
}

.pageContainer {
  height: 100%;
  min-height: calc(100vh - 50px);
  overflow-x: hidden;
  width: 100%;
  max-width: calc(100vh - 32px) !important;
  overflow-x: hidden;
  position: "relative";
  z-index: 25 !important;
}

/* .pageContainer::before {
  content: "";
  position: absolute;
  width: calc(100% + 32px);
  height: 125px;
  background-image: url("./bg-top-2.png");
  background-size: contain;
  top: -14px;
  left: -16px;
  z-index: 1 !important;
  background-repeat: no-repeat;
}

.pageContainer::after {
  content: "";
  position: absolute;
  width: calc(100% + 32px);
  height: 125px;
  background-image: url("./bg-bottom.png");
  background-size: cover;
  bottom: 0px;
  left: -16px;
  z-index: 1 !important;
  background-repeat: no-repeat;
} */

/* HOMEPAGE START */
.homepage {
  background-size: cover;
  background-repeat: repeat;
}
.hashtag {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 4;
}

.hashtag::before {
  content: "";
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 5px;
  background-color: #f0f0f0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.hashtagBadge {
  position: relative;
  z-index: 25;
  margin-left: auto;
  margin-right: auto;
  padding: 4px 16px;
  font-weight: 700;
  font-size: 16px;
  background-color: #f0f0f0;
  display: inline-block;
  border-radius: 10rem;
  z-index: 5;
  border: 2px solid #fff;
}
/* HOMEPAGE END */

/* TRANSITIONS */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.switch-wrapper {
  position: relative;
  width: 100%;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

/* VIDEO CAROUSEL START */
.videoContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  background: #213978;
  overflow: hidden;
}

.videoContainer .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.carouselContainer {
  width: 100%;
  max-width: calc(100vh - 32px) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* SUCCESS MODAL */
/* .successModal {
  background: transparent !important;
}

.successModal .chakra-modal__header {
  background: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.successModal .chakra-modal__body {
  background: white;
}

.successModal .chakra-modal__footer {
  padding: 0px;
} */
